<template>
    <onboarding-layout :header="false">
        <img
            src="@/assets/images/notary/phoneNotWorking.svg"
            width="200px"
            alt="Photo Not Working"
        >
        <h5 class="fw-light">
            For our notary process to work, please copy the link below and switch to Chrome or Android Browser
        </h5>
        <div class="d-grid">
            <a
                class="btn btn-secondary mt-3"
                href="#"
                id="copyButton"
            > Copy Link </a>
        </div>
        <div
            v-show="showSuccess"
            class="alert alert-success mt-2"
            role="alert"
        >
            <strong>Link Copied</strong><br>
            Open Chrome / Android Browser and paste the link in to the top bar.
        </div>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import clipboard from 'clipboard'
    import { getSessionLink } from '@/services/schedulingApi'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        data: function () {
            return {
                linkToCopy: null,
                showSuccess: false,
            }
        },
        mounted: async function () {
            const response = await getSessionLink()
            this.linkToCopy = response.data.payload

            new clipboard('#copyButton', {
                text: this.copyLink,
            })

            this.$logEvent('view_notary_requires_chrome')
        },
        methods: {
            copyLink: function () {
                logger.log(`Copying link ${this.linkToCopy} to clipboard`)
                this.showSuccess = true
                return this.linkToCopy
            },
        },
    }
</script>
